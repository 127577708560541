import * as React from 'react'

import { NavBar } from '../components/nav-bar'

import styles from './404.module.scss'
import { Button } from 'components/button'
import { Typography } from 'components/typography'
import { BackgroundImage } from 'components/background-image'

export default function Custom404() {
  return (
    <>
      <BackgroundImage src={'/background/404_Smoke_1.png'} />

      <NavBar />
      <div className={styles.pageContent}>
        <h1 className={styles.heading}> 404</h1>
        <Typography variant="sub-heading-2" className={styles.comment}>
          The page you are looking for may not exist.
          <br /> <br />
          It would be wise to return before the Librarian notices what you are
          doing...
        </Typography>

        <Button
          as="link"
          to="/"
          color="primary"
          fitContent
          className={styles.button}
        >
          Return to Foyer
        </Button>
      </div>
    </>
  )
}
