import Image from "next/image";
import * as React from "react";
import styles from "./background-image.module.scss";

type BackgroundImageProps = {
  src: string;
  mobileSrc?: string;
  className?: string;
  mobileClassName?: string;
};

export const BackgroundImage = React.forwardRef<
  HTMLDivElement,
  BackgroundImageProps
>(
  (
    { src, mobileSrc, className, mobileClassName }: BackgroundImageProps,
    ref
  ) => {
    return (
      <div ref={ref} className={styles.background}>
        <Image
          src={src}
          alt="Background Image"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          quality={100}
          priority={true}
          className={className}
        />
        {mobileSrc && (
          <Image
            src={mobileSrc}
            alt="Background Image"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            quality={100}
            priority={true}
            className={mobileClassName}
          />
        )}
      </div>
    );
  }
);

BackgroundImage.displayName = "BackgroundImage";
